import FormMixin from "@/mixins/FormMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";

export default {
  mixins: [FormMixin],
  methods: {
    uploadDocument(form) {
      // NOTA Chiamo la RepositoryFactory per evitare di chimare la funzione removeEmpty del mixin
      this.$refs[this.tableRef].isLoading = true;
      const Repo = RepositoryFactory.get("document");
      Repo.store(form)
        .then((response) => {
          const data = response.data.data;
          this.addToDocument(data.id)
            .then(() => {
              this.$refs[this.tableRef].isLoading = false;
              let criteria = this.filter;
              let name = this.filterName;
              this.saveFilterByName({ name, criteria });
              this.$refs[this.tableRef]
                .fetch()
                .then(() => {
                  this.$emit("fetch");
                  this.$showSnackbar({
                    preset: "success",
                    text: `Documento Caricato`,
                  });
                  this.removeFilterByName(name);
                })
                .catch((error) => {
                  let errMsg = this.$getErrorMessage(error);
                  this.$showSnackbar({ preset: "error", text: errMsg });
                });
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
              this.$refs[this.tableRef].isLoading = false;
            });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.$refs[this.tableRef].isLoading = false;
        });
    },
    addToDocument(document_id) {
      let payload = {
        [this.relation]: { [this.resourceId]: {} },
      };
      const Repo = RepositoryFactory.get("document");
      return Repo.pivot_store(document_id, this.relation, payload);
    },
    deleteDocument(id) {
      const Repo = RepositoryFactory.get(this.repository);
      Repo.destroy(id)
        .then(() => {
          let criteria = this.filter;
          let name = this.filterName;
          this.saveFilterByName({ name, criteria });
          this.$refs[this.tableRef]
            .fetch()
            .then(() => {
              this.$emit("fetch");
              this.$showSnackbar({
                preset: "success",
                text: `Documento Eliminato`,
              });
              this.removeFilterByName(name);
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({ preset: "error", text: errMsg });
            });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    downloadDocument(doc) {
      const Repo = RepositoryFactory.get("document");
      return Repo.download(doc.id)
        .then((response) => {
          const blob = new Blob([response.data], { type: response.data.type });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `${doc.title}.${doc.extension}`;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(() => {
          this.$showSnackbar({
            preset: "error",
            text: `Errore durante il download del documento`,
          });
        });
    },
  },
};
