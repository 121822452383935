<template>
  <div class="mt-1">
    <b-card header="true" header-tag="header" class="mt-2">
      <div
        slot="header"
        class="d-flex justify-content-between align-items-center"
      >
        <p class="mb-0 d-inline-block align-middle">
          <b>Informazioni</b>
        </p>
      </div>
      <b-row v-for="(field, index) in fields" :key="index">
        <div class="col-md-4 border-bottom">
          {{ field.label.toUpperCase() }}
        </div>
        <div class="col-md-8 border-bottom">
          {{ renderDbRowData(field, dbRowData) }}
        </div>
      </b-row>
    </b-card>
    <b-card header="true" header-tag="header" class="mt-2">
      <div
        slot="header"
        class="d-flex justify-content-between align-items-center"
      >
        <p class="mb-0 d-inline-block align-middle">
          <b>Abbina</b>
        </p>
      </div>
      <b-row>
        <div class="col-md-3">
          <base-select
            name="model"
            label="Scegli entità da abbinare"
            :options="[
              { value: 'registry', text: 'Anagrafica' },
              { value: 'insurance_policy', text: 'Polizza' },
            ]"
            v-model="entity"
            @select="onSelectEntity"
            @remove="onRemoveEntity"
          />
        </div>
        <div class="form-group col-md-6" v-if="entity === 'registry'">
          <div>
            <b-button-toolbar>
              <b-button-group>
                <base-input
                  name="Anagrafica"
                  label="Anagrafica"
                  v-model="form.registry.label"
                  :readonly="true"
                />
                <b-button
                  class="mt-4 btn-quick"
                  size="sm"
                  text="Button"
                  variant="lisaweb"
                  title="Ricerca Veloce"
                  @click="openQuickSearchRegistry"
                  ><b-icon icon="search"></b-icon
                ></b-button>
              </b-button-group>
            </b-button-toolbar>
          </div>
        </div>
        <quick-search-registry-modal
          ref="quickSearchRegistry"
          @input="handleRegistryModalInput"
        >
        </quick-search-registry-modal>
        <div class="form-group col-md-6" v-if="entity === 'insurance_policy'">
          <div>
            <b-button-toolbar>
              <b-button-group>
                <base-input
                  name="Polizza"
                  label="Polizza"
                  v-model="form.insurance_policy.label"
                  :readonly="true"
                />
                <b-button
                  class="mt-4 btn-quick"
                  size="sm"
                  text="Button"
                  variant="lisaweb"
                  title="Ricerca Veloce"
                  @click="openQuickSearchPolicy"
                  ><b-icon icon="search"></b-icon
                ></b-button>
              </b-button-group>
            </b-button-toolbar>
          </div>
        </div>
        <quick-search-policy-modal
          ref="quickSearchPolicy"
          @input="handlePolicyModalInput"
        ></quick-search-policy-modal>
      </b-row>
      <b-row>
        <div class="form-group col-md-3 mt-4">
          <b-button
            class="btn-create"
            type="button"
            variant="lisaweb"
            size="sm"
            title="Abbina"
            @click="onSave"
            :disabled="!form.registry.value && !form.insurance_policy.value"
          >
            Abbina</b-button
          >
        </div>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";
import { renderDbRowData } from "@/utils/payments";
import BaseSelect from "@/components/form/BaseSelect";
import QuickSearchRegistryModal from "@/components/modals/quickSearchRegistry";
import QuickSearchPolicyModal from "@/components/modals/quickSearchPolicy";
import { toInfoData } from "@/utils/transforms";
import BaseInput from "@/components/form/BaseInput";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";

export default {
  mixins: [FormMixin],
  name: "General",
  props: {
    resourceId: Number,
    modalName: {
      type: String,
      default() {
        return "";
      },
    },
    dbRowData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    BaseSelect,
    QuickSearchRegistryModal,
    QuickSearchPolicyModal,
    BaseInput,
  },
  data() {
    return {
      isLoading: false,
      entity: null,
      repository: "document",
      form: {
        registry: {
          label: null,
          value: null,
        },
        insurance_policy: {
          label: null,
          value: null,
        },
      },
      fields: [
        {
          value: "title",
          label: this.getDictionary("title", "document"),
        },
        {
          value: "note",
          label: this.getDictionary("note", "document"),
        },
        {
          value: "document_type.text",
          label: this.getDictionary("document_type", "document"),
        },
        {
          value: "metadata",
          label: this.getDictionary("metadata", "document"),
        },
      ],
    };
  },
  methods: {
    renderDbRowData,
    toInfoData,
    resetEntity() {
      this.form.registry.label = null;
      this.form.registry.value = null;
      this.form.insurance_policy.label = null;
      this.form.insurance_policy.value = null;
    },
    onSelectEntity() {
      this.resetEntity();
    },
    onRemoveEntity() {
      this.resetEntity();
    },
    openQuickSearchRegistry() {
      this.$bvModal.show("quickSearchRegistryModal");
    },
    handleRegistryModalInput(value) {
      this.form.registry.label = this.toInfoData(value, "registryfullname");
      this.form.registry.value = value.id;
      this.$bvModal.hide("quickSearchRegistryModal");
    },
    handlePolicyModalInput(value) {
      this.form.insurance_policy.value = value.id;
      this.form.insurance_policy.label = value.number;
      this.$bvModal.hide("quickSearchPolicyModal");
    },
    openQuickSearchPolicy() {
      this.$bvModal.show("quickSearchPolicyModal");
    },
    onSave() {
      this.addToDocument()
        .then(() => {
          this.$bvModal.hide(this.modalName);
          this.$emit("fetch");
          this.$showSnackbar({
            preset: "success",
            text: `Documento Abbinato`,
          });
        })
        .catch(() => {
          this.$showSnackbar({
            preset: "error",
            text: `Errore durante la procedura`,
          });
        });
    },
    addToDocument() {
      const entityId =
        this.entity === "registry"
          ? this.form.registry.value
          : this.form.insurance_policy.value;
      let payload = {
        [this.entity]: { [entityId]: {} },
      };
      const Repo = RepositoryFactory.get(this.repository);
      return Repo.pivot_store(this.resourceId, this.entity, payload);
    },
  },
};
</script>
<style scoped lang="scss">
.col-md-4.border-bottom {
  color: rgb(63, 60, 60);
  font-size: bold;
}
</style>
