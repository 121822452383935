<template>
  <b-modal
    id="addDocumentModal"
    size="lg"
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
    no-close-on-backdrop
  >
    <template #modal-header="{ cancel }">
      <div slot="modal-title" class="modal-title">
        <b-icon icon="file-earmark"></b-icon>
        <span>Carica Documento</span>
      </div>
      <button
        type="button"
        class="close"
        title="Chiudi"
        data-dismiss="modal"
        @click="
          cancel();
          close();
        "
      >
        &times;
      </button>
    </template>
    <validation-observer ref="observer2" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.prevent="">
        <b-card header="Carica Documento" header-tag="header">
          <b-card-text>
            <b-row>
              <div class="form-group col-md-4">
                <base-input
                  vid="title"
                  :name="beFormCreate.title.label"
                  :label="beFormCreate.title.label"
                  v-model="form.title"
                  :placeholder="beFormCreate.title.label"
                  :rules="getRules('title', 'document')"
                />
              </div>
              <div class="form-group col-md-4">
                <base-select
                  vid="document_type"
                  :name="beFormCreate.document_type.label"
                  :label="beFormCreate.document_type.label"
                  v-model="form.document_type"
                  :options="beFormCreate.document_type.options"
                  :rules="getRules('document_type', 'document')"
                />
              </div>
              <div class="form-group col-md-4">
                <base-textarea
                  vid="note"
                  :name="beFormCreate.note.label"
                  :label="beFormCreate.note.label"
                  v-model="form.note"
                  placeholder="Inserisci una nota"
                  :rules="getRules('note', 'document')"
                />
              </div>
            </b-row>
            <b-row>
              <div class="form-group col-md-6">
                <base-form-file
                  v-model="file"
                  vid="base_form_file"
                  name="Carica File"
                  label="Carica  File"
                  :rules="{ required: true }"
                ></base-form-file>
              </div>
              <div class="form-group col-md-4 mt-4">
                <b-button
                  size="sm"
                  variant="lisaweb"
                  @click="file = null"
                  :disabled="file ? false : true"
                  title="Cancella File"
                >
                  Cancella File
                </b-button>
              </div>
            </b-row>
          </b-card-text>
        </b-card>
        <b-button
          type="button"
          @click="handleSubmit(upload)"
          :disabled="invalid"
          variant="outline-lisaweb"
          size="sm"
          class="mt-2 float-right"
        >
          Carica
        </b-button>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
import BaseTextarea from "@/components/form/BaseTextarea";
import BaseSelect from "@/components/form/BaseSelect";
import BaseInput from "@/components/form/BaseInput";
import BaseFormFile from "@/components/form/BaseFormFile";
import FormMixin from "@/mixins/FormMixin";
import { mapGetters } from "vuex";

export default {
  mixins: [FormMixin],
  data() {
    return {
      form: {
        title: null,
        document_type: null,
        note: null,
        user_id: null,
        source_type: 0,
      },
      file: null,
    };
  },
  components: {
    BaseInput,
    BaseSelect,
    BaseTextarea,
    BaseFormFile,
  },
  methods: {
    upload() {
      this.form["file"] = this.file;
      let formData = new FormData();
      formData.append("file", this.form.file);
      if (this.form.note) {
        formData.append("note", this.form.note);
      }
      formData.append("document_type", this.form.document_type);
      formData.append("title", this.form.title);
      formData.append("source_type", this.form.source_type);
      console.log("FormData: ", formData);
      console.log("form: ", this.form);

      this.$emit("upload", formData);
      this.$bvModal.hide("addDocumentModal");
      this.resetModalData();
    },
    close() {
      this.$bvModal.hide("addDocumentModal");
      this.resetModalData();
    },
    resetModalData() {
      this.file = null;
      this.form.note = null;
      this.form.document_type = null;
      this.form.title = null;
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  beforeMount() {
    this.beRules["document"] = this.beRulesCreate;
    this.form.user_id = this.user.id;
  },
  props: {
    beRulesCreate: {
      type: Object,
      default() {
        return {};
      },
    },
    beFormCreate: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(tbody) {
  border-top: 0px !important;
}
:deep(table td) {
  border-top: 0px solid #dee2e6;
}
</style>
