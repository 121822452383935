<template>
  <validation-provider
    :mode="vmode"
    :vid="vid"
    :name="name"
    :rules="rules"
    v-slot="{ dirty, validated, valid, errors }"
  >
    <b-form-group
      :id="`input-group-${id}`"
      :label="label"
      :label-for="`input-${id}`"
      :class="{
        asterisk:
          Object.keys(rules).includes('required') ||
          (Object.keys(rules).includes('required_if') && !valid),
      }"
    >
      <b-form-file
        :id="`input-${id}`"
        :name="`input-${id}`"
        v-model="inputVal"
        :state="getValidationState({ dirty, validated, valid, errors })"
        placeholder="Scegli un file o trascinalo qui..."
        drop-placeholder="Trascina il file qui..."
        browse-text="Ricerca"
        :accept="accept"
        :disabled="disabled"
      ></b-form-file>
      <b-form-invalid-feedback :id="`input-${id}-live-feedback`">{{
        errors[0]
      }}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>
<script>
export default {
  props: {
    value: null,
    vid: String,
    name: String,
    label: String,
    placeholder: String,
    maxlength: {
      default: "50",
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    accept: {
      default: ".doc, .docx, .pdf, .odt, .png, .jpg, .jpeg, .gz, .txt, .zip",
      type: String,
    },
    rules: {
      type: Object,
      default() {
        return {};
      },
    },
    vmode: {
      type: String,
      default: "aggressive",
    },
  },
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id = this._uid;
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null, errors = null }) {
      if (errors[0]) return false;
      // return dirty || validated ? valid : null;
      return !Object.keys(this.rules).length
        ? null
        : dirty || validated
        ? valid
        : null;
    },
  },
};
</script>
